import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useGeolocated } from 'react-geolocated';
// import { FaInstagram } from 'react-icons/fa';
import { MdClose, MdMenu } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';

import logoBlack from '@/public/assets/logo-old-black.png';
import logo from '@/public/assets/logo-old.png';

import { navItems } from '@/config/navigation.config';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

import { logFirebaseEvent } from '@/utils/firebase.utils';

import AccessibilitySettings from '@/components/AccessibilitySettings';

function MobileMenu() {
  const router = useRouter();

  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const handleMobileMenuClose = () => setMobileMenuOpen(false);
  const toggleMobileMenu = () => setMobileMenuOpen(prevState => !prevState);

  const isLinkActive = (href: string) => {
    return router.asPath === href;
  };

  const linkActiveClasses = 'bg-primary-alt-500';
  const linkHoverClasses = 'hover:bg-primary-alt-500';

  return (
    <>
      <button
        type="button"
        onClick={toggleMobileMenu}
        className="bg-primary-alt-500 flex items-center justify-center lg:hidden h-full w-[60px]"
      >
        <span className="sr-only">Toggle mobile menu</span>
        <MdMenu className="w-7 h-7 text-white" aria-hidden="true" />
      </button>

      <Transition.Root show={mobileMenuOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-20 block lg:hidden"
          onClose={handleMobileMenuClose}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#374249] bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={React.Fragment}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-sm">
                    <div className="flex h-full flex-col overflow-y-scroll bg-[#374249] py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <button type="button" onClick={handleMobileMenuClose}>
                          <span className="sr-only">Close mobile menu</span>
                          <MdClose
                            className="w-7 h-7 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>

                      <div className="relative mt-6 flex-1">
                        {/* mobile - navigation */}
                        <nav role="navigation" aria-label="mobile navigation">
                          {navItems.map(item => (
                            <Link key={item.href} href={item.href}>
                              <a
                                className={twMerge(
                                  'block px-4 sm:px-6 py-4',
                                  'text-white text-lg',
                                  'border-b border-[#333e45] first:border-t',
                                  isLinkActive(item.href)
                                    ? linkActiveClasses
                                    : '',
                                  linkHoverClasses
                                )}
                              >
                                {item.label}
                              </a>
                            </Link>
                          ))}
                        </nav>

                        {/* mobile - accessibility options */}
                        <Disclosure as="div" className="mt-6 px-4 sm:px-6">
                          <Disclosure.Button className="btn btn-primary-alt w-full px-4 sm:px-6 py-4">
                            Fonts and Colours
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2">
                            <AccessibilitySettings
                              variant="column"
                              className="text-white"
                            />
                          </Disclosure.Panel>
                        </Disclosure>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function TopNavigation() {
  const router = useRouter();

  const { useHighContrast } = useAccessibilitySettings();

  const { getPosition } = useGeolocated({
    suppressLocationOnMount: true,
    onError: positionError => {
      alert(
        `Sorry, it seems like we weren't able to get your location. Please check that you have location services enabled and try again.`
      );
      console.error(positionError);
    },
    onSuccess: position => {
      router.push(
        `/restaurants/search?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`
      );
      logFirebaseEvent('click_near_me');
    },
  });

  const handleNearMeButtonClick = () => {
    getPosition();
  };

  return (
    <div
      className={twMerge(
        'h-[56px] lg:h-[72px]',
        useHighContrast
          ? 'bg-contrast-yellow text-black'
          : 'bg-primary-500 text-white'
      )}
    >
      <div className="h-full pl-2 lg:container flex items-center justify-between">
        <div className="h-full flex items-center">
          {/* logo */}
          <div className="pt-2 md:pt-4 md:pb-2">
            <Link href="/">
              <a>
                <Image
                  src={useHighContrast ? logoBlack : logo}
                  alt="goodfoodtalks logo"
                />
              </a>
            </Link>
          </div>

          {/* socials */}
          <div className="hidden lg:flex items-center ml-8">
            {/* <span className="sr-only">Social links</span>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2"
            >
              <FaInstagram className="h-5 w-5 text-inherit" />
              <span
                className={twMerge(
                  'sr-only xl:not-sr-only text-xl',
                  useHighContrast ? 'font-normal' : 'font-light'
                )}
              >
                Instagram
              </span>
            </a> */}
          </div>
        </div>

        {/* near me quick button */}
        <button
          type="button"
          onClick={handleNearMeButtonClick}
          className={twMerge(
            'btn btn-xl hidden lg:block',
            useHighContrast ? 'btn-contrast-yellow' : 'btn-primary-alt'
          )}
        >
          Near me
        </button>

        {/* mobile menu */}
        <MobileMenu />
      </div>
    </div>
  );
}

function BottomNavigation() {
  const router = useRouter();

  const { fontSize, useHighContrast } = useAccessibilitySettings();
  const isUsingLargeFont = fontSize === 'large' || fontSize === 'extra-large';

  const isLinkActive = (href: string) => {
    return router.asPath === href;
  };

  const linkActiveClasses = useHighContrast
    ? 'border-b-black'
    : 'border-b-primary-500';
  const linkHoverClasses = useHighContrast
    ? 'hover:border-b-black'
    : 'hover:border-b-primary-500';

  return (
    <nav
      role="navigation"
      aria-label="navigation"
      className={twMerge(
        'h-[46px] hidden lg:block',
        useHighContrast
          ? 'bg-contrast-yellow text-black border-black border-b'
          : 'bg-secondary-500 text-white'
      )}
    >
      <div className="container h-full flex items-stretch justify-between">
        {/* nav items */}
        <div className="flex space-x-4 xl:space-x-8">
          {navItems.map(item => (
            <Link key={item.href} href={item.href}>
              <a
                className={twMerge(
                  'text-inherit font-bold text-sm flex items-center',
                  'border-b-[5px] border-t-[5px] border-t-transparent transition-all',
                  isLinkActive(item.href)
                    ? linkActiveClasses
                    : 'border-b-transparent',
                  linkHoverClasses
                )}
              >
                {item.icon}
                {item.label}
              </a>
            </Link>
          ))}
        </div>

        {/* accessibility options */}
        <Popover
          className={twMerge(
            'relative block self-center',
            isUsingLargeFont ? '2xl:hidden' : 'xl:hidden'
          )}
        >
          <Popover.Button
            className={twMerge(
              'btn btn-sm self-center',
              useHighContrast ? 'btn-contrast-yellow' : 'btn-primary-alt'
            )}
          >
            Fonts and Colours
          </Popover.Button>
          <Popover.Panel
            className={twMerge(
              'absolute right-0 z-20 mt-2',
              isUsingLargeFont ? 'w-[340px]' : 'w-[280px]'
            )}
          >
            <AccessibilitySettings
              variant="column"
              className={twMerge(
                'p-2 rounded-sm',
                useHighContrast
                  ? 'bg-contrast-yellow text-black border border-black'
                  : 'bg-secondary-500 text-white'
              )}
            />
          </Popover.Panel>
        </Popover>

        <div
          className={twMerge(
            'hidden self-center',
            isUsingLargeFont ? '2xl:block' : 'xl:block'
          )}
        >
          <AccessibilitySettings
            className={twMerge(useHighContrast ? 'text-black' : 'text-white')}
          />
        </div>
      </div>
    </nav>
  );
}

function Header() {
  return (
    <header className="fixed left-0 top-0 right-0 z-20">
      <TopNavigation />
      <BottomNavigation />
    </header>
  );
}

export default Header;
