import Image from 'next/image';
import Link from 'next/link';
import { FaEnvelope } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

import logoBlack from '@/public/assets/logo-black.png';
import logo from '@/public/assets/logo.png';

import { footerColumns } from '@/config/navigation.config';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

function NewsletterBar() {
  const { useHighContrast } = useAccessibilitySettings();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO
  };

  return (
    <section
      className={twMerge(
        useHighContrast
          ? 'bg-contrast-yellow text-black'
          : 'bg-[#224149] text-white'
      )}
    >
      <div className="container flex flex-col xl:flex-row items-center justify-between py-[28px]">
        <div className="text-center xl:text-left mb-4 xl:mb-0">
          <h2 className="text-2xl">Keep up-to-date</h2>
          <p className="font-light">
            Sign up for notifications when restaurants sign up near you
          </p>
        </div>

        <form
          onSubmit={handleFormSubmit}
          className={twMerge(
            'w-full md:w-auto flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-2'
          )}
        >
          <div className="w-full md:w-[328px] relative">
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email address"
              aria-label="Email address"
              className={twMerge(
                'rounded-sm w-full h-[38px] pl-10 text-black',
                useHighContrast
                  ? 'bg-contrast-yellow border border-black placeholder:text-black'
                  : ''
              )}
            />
            <FaEnvelope
              className={twMerge(
                'w-6 h-6 absolute left-2 top-[7px]',
                useHighContrast ? '' : 'text-[#224149]'
              )}
              aria-hidden="true"
            />
          </div>

          <button
            type="submit"
            className={twMerge(
              'btn w-full md:w-[226px] h-[38px]',
              useHighContrast ? 'btn-contrast-yellow' : 'btn-primary'
            )}
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
}

function Navigation() {
  const { useHighContrast } = useAccessibilitySettings();

  const columnClasses = twMerge(
    'p-4 space-y-1',
    useHighContrast
      ? 'bg-contrast-yellow text-black border border-black'
      : 'bg-[#224149] text-white'
  );

  return (
    <section
      className={twMerge(
        'py-[47px]',
        useHighContrast
          ? 'bg-contrast-yellow text-black'
          : 'bg-[#16343c] text-white'
      )}
    >
      <nav
        role="navigation"
        className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6"
      >
        {footerColumns.map(column => (
          <div key={column.title} className={columnClasses}>
            <h2 className="text-inherit mb-2 text-lg font-bold">
              {column.title}
            </h2>

            {Array.isArray(column.items)
              ? column.items.map(item => (
                  <Link key={item.href} href={item.href}>
                    <a
                      className={twMerge(
                        'block font-light text-lg',
                        useHighContrast ? 'link-contrast' : 'link-white'
                      )}
                    >
                      {item.label}
                    </a>
                  </Link>
                ))
              : null}
          </div>
        ))}
      </nav>
    </section>
  );
}

function BottomBar() {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <section
      className={twMerge(
        'pb-[30px]',
        useHighContrast
          ? 'bg-contrast-yellow text-black'
          : 'bg-[#16343c] text-white'
      )}
    >
      <div className="container flex flex-col md:flex-row items-center md:items-end justify-between">
        <Link href="/">
          <a>
            <Image
              src={useHighContrast ? logoBlack : logo}
              alt="goodfoodtalks logo"
              width="180px"
              height="55px"
            />
          </a>
        </Link>

        <div
          className={twMerge(
            'flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-2 pt-4 md:pt-0',
            useHighContrast ? 'sm:divide-x sm:divide-black' : 'sm:divide-x'
          )}
        >
          <Link href="/terms-and-conditions">
            <a
              className={twMerge(
                'font-light text-lg',
                useHighContrast ? 'link-contrast' : 'link-white'
              )}
            >
              Terms & Conditions
            </a>
          </Link>

          <Link href="/privacy-policy">
            <a
              className={twMerge(
                'font-light text-lg sm:pl-2',
                useHighContrast ? 'link-contrast' : 'link-white'
              )}
            >
              Privacy Policy
            </a>
          </Link>

          <p className="font-light text-lg sm:pl-2">
            Site by{' '}
            <a
              href="https://gearedapp.co.uk/"
              target="_blank"
              rel="noopener noreferrer"
              className={twMerge(
                useHighContrast ? 'link-contrast' : 'link-white'
              )}
            >
              GearedApp
            </a>
          </p>
        </div>
      </div>

      <div className="container mt-8 md:mt-4">
        <p className="text-center md:text-right font-light text-xs">
          Google Play and the Google Play logo are trademarks of Google LLC.
        </p>
      </div>
    </section>
  );
}

function Footer() {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <footer
      className={twMerge(useHighContrast ? 'border-t border-t-black' : null)}
    >
      {/* <NewsletterBar /> */}
      <Navigation />
      <BottomBar />
    </footer>
  );
}

export default Footer;
